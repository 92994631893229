import React from 'react';
import PropTypes from 'prop-types';

import ContentList from '@mshops-components-library/content-list';

import { useRenderContext } from '../../pages/home/context';

const ContentListContainer = ({ elements, title, deviceType, title_enabled: titleEnabled }) => {
  const { isEshops, theme } = useRenderContext();

  return (
    <ContentList
      deviceType={deviceType}
      elements={elements}
      title={title}
      titleEnabled={titleEnabled}
      isEshops={isEshops}
      lazyLoading="on"
      layoutTheme={theme}
    />
  );
};

ContentListContainer.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      align: PropTypes.string.isRequired,
      button: PropTypes.shape({
        button_href: PropTypes.string.isRequired,
        button_text: PropTypes.string.isRequired,
        hidden: PropTypes.bool.isRequired,
      }),
      description: PropTypes.shape({
        hidden: PropTypes.bool.isRequired,
        text: PropTypes.string.isRequired,
      }),
      element_title: PropTypes.shape({
        hidden: PropTypes.bool.isRequired,
        text: PropTypes.string.isRequired,
      }),
      image: PropTypes.shape({
        desktop: PropTypes.string.isRequired,
        desktop_id: PropTypes.string.isRequired,
        hidden: PropTypes.bool.isRequired,
        mobile: PropTypes.string.isRequired,
        mobile_id: PropTypes.string.isRequired,
      }),
    }),
  ).isRequired,
  deviceType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  title_enabled: PropTypes.bool.isRequired,
};

export default ContentListContainer;
